import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { AutoTooltip } from "nlib/ui";
import { checkIsBusinessUser } from "selectors/user";
import { getCurrentQuickBooksRealmId, getSelectedBusinessCategories, getSelectedBusinessData } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { getTransactionsReasons } from "selectors/transactions";
import { getVendorsCategories } from "selectors/contacts";
import { useSelector } from "react-redux";
import Constants from "const/Constants";
import DataConstants from "const/DataConstants";
import IntegrationServices from "const/IntegrationServices";
import React, { useCallback, useMemo } from "react";
import Utils from "utils/Utils";

const { AUDIT_SUBSECTIONS_ROUTES: { UNUSUAL_AMOUNT } } = Constants;

const { STATUSES: { NEED_REACTION, TO_RECONCILE, TO_REVIEW, EXPORTED } } = DataConstants;

const AdditionalInfo = (props) => {
  const {
    disabled,
    auditRoute,
    usesItems,
    type,
    status,
    vendorId,
    initialReason,
    category: {
      code: categoryCode = "",
      setByClient: categorySetByClient = false,
      setByRule: categorySetByRule = false
    } = {},
    initialCategory: {
      code: initialCategoryCode = ""
    } = {},
    item: {
      setByClient: itemSetByClient = false
    } = {},
    disabledCategorySelect,
    onCategoryChange
  } = props;

  const { uiTexts, messages } = useSelector(getTextsData);

  const vendorsCategories = useSelector(getVendorsCategories);

  const quickBooksBusiness = !!useSelector(getCurrentQuickBooksRealmId);

  const businessUser = useSelector(checkIsBusinessUser);

  const transactionsReasons = useSelector(getTransactionsReasons);

  const categories = useSelector(getSelectedBusinessCategories);

  const { extraData: { integrationService } = {} } = useSelector(getSelectedBusinessData);

  const vendorCategories = vendorId ? vendorsCategories[vendorId] : null;

  const categoryChanged = categoryCode !== initialCategoryCode;

  const showWarning = useMemo(() => {
    const { categoryCodes = [], mostUsedCode = null, lastUsedCode = null } = vendorCategories || {};

    if (auditRoute) {
      return auditRoute === UNUSUAL_AMOUNT && !categoryChanged;
    }

    const allowedStatus = (
      status === TO_RECONCILE || status === TO_REVIEW || status === NEED_REACTION || (quickBooksBusiness && (status === EXPORTED))
    );

    return allowedStatus && !usesItems && !!categoryCodes.length
      && categoryCode && categoryCode !== mostUsedCode && categoryCode !== lastUsedCode;
  }, [auditRoute, categoryChanged, status, usesItems, vendorCategories, categoryCode, quickBooksBusiness]);

  const suggestedCategory = useMemo(() => {
    const suggestedCategoryCode = initialReason && Utils.arrayFind(transactionsReasons, "reason", initialReason, {}).categoryCode;

    return suggestedCategoryCode && Utils.arrayFind(categories, "code", suggestedCategoryCode);
  }, [categories, initialReason, transactionsReasons]);

  const handleCategoryLinkClick = useCallback(() => {
    onCategoryChange(suggestedCategory);
  }, [suggestedCategory, onCategoryChange]);

  if (auditRoute === Constants.AUDIT_SUBSECTIONS_ROUTES.UNUSUAL_AMOUNT && showWarning) {
    return (
      <div className={Css.additionalInfo}>
        <AutoTooltip className={Css.description}>
          <Icons.Warning className={Css.negative} />
          <span>
            {type === DataConstants.TRANSACTION_TYPES.DEPOSIT ? messages.unusualAmountDeposit : messages.unusualAmountWithdraw}
          </span>
        </AutoTooltip>
      </div>
    );
  }

  if (suggestedCategory && !usesItems && !categoryCode && !businessUser && status === TO_REVIEW && !disabledCategorySelect) {
    const [categorySuggestionTextA, categorySuggestionTextB] = messages.categorySuggestion;

    return (
      <div className={Css.additionalInfo}>
        <AutoTooltip className={Css.description}>
          <Icons.SquaresFour className={Css.highlight} />
          <span>{`${categorySuggestionTextA}: `}</span>
          <a onClick={handleCategoryLinkClick} disabled={disabled}>
            {suggestedCategory && suggestedCategory.displayName}
          </a>
          <span>{`. ${categorySuggestionTextB}: `}</span>
          <b>{initialReason}</b>
        </AutoTooltip>
      </div>
    );
  }

  if (businessUser) return null;

  if (!auditRoute) {
    if (initialReason) {
      return (
        <div className={Css.additionalInfo}>
          <AutoTooltip className={Css.description}>
            <Icons.Chat className={Css.highlight} />
            <span><b>{initialReason}</b></span>
          </AutoTooltip>
        </div>
      );
    }

    if ((usesItems ? itemSetByClient : categorySetByClient)) {
      return (
        <div className={Css.additionalInfo}>
          <AutoTooltip className={Css.description}>
            <Icons.User className={Css.highlight} />
            <span>{uiTexts.categorizedByClient}</span>
          </AutoTooltip>
        </div>
      );
    }

    if (!usesItems && categorySetByRule) {
      const integrationServiceName = IntegrationServices.getByValue(integrationService).shortLabel;

      return (
        <div className={Css.additionalInfo}>
          <AutoTooltip className={Css.description}>
            <Icons.Info className={Css.highlight} />
            <span>{Utils.replaceTextVars(uiTexts.categorizedByServiceRule, { service: integrationServiceName })}</span>
          </AutoTooltip>
        </div>
      );
    }
  }

  return null;
};

export default React.memo(AdditionalInfo);
