import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { getTextsData } from "selectors/texts";
import { useDispatch, useSelector } from "react-redux";
import { useInViewport } from "react-in-viewport";
import MainApiActions from "actions/MainApiActions";
import Preloader from "nlib/common/Preloader";
import React, { useCallback, useEffect, useRef, useState } from "react";
import UiActions from "actions/UiActions";
import Utils from "utils/Utils";
import classNames from "classnames";

const DocumentPreview = (props) => {
  const {
    error,
    className,
    children,
    documentPreview,
    onClick
  } = props;

  const rootRef = useRef();

  const abortRef = useRef(false);

  const { inViewport } = useInViewport(rootRef);

  const dispatch = useDispatch();

  const { uiTexts, documentUpdateActions } = useSelector(getTextsData);

  const [imageSrc, setImageSrc] = useState(null);

  const [imageLoaded, setImageLoaded] = useState(false);

  const fetchData = useCallback(async() => {
    setImageLoaded(false);
    setImageSrc(null);

    const response = await dispatch(MainApiActions.fetchAttachmentUrl(documentPreview, false, true));

    if (abortRef.current) return null;

    setImageSrc(response);
    await Utils.preloadImages([response]);
    if (abortRef.current) return null;
    setImageLoaded(true);

    return response;
  }, [dispatch, documentPreview]);

  const handlePreviewClick = useCallback(async(event) => {
    event.stopPropagation();

    if (onClick) onClick();
    else if (imageSrc) {
      dispatch(UiActions.showModalImages([""]));
      try {
        await Utils.preloadImages([imageSrc]);
        dispatch(UiActions.showModalImages([imageSrc]));
      } catch (exception) {
        const response = await fetchData();

        dispatch(UiActions.showModalImages([response]));
      }
    }
  }, [dispatch, fetchData, imageSrc, onClick]);

  useEffect(() => {
    if (!inViewport || imageSrc || !documentPreview) return;
    fetchData();
  }, [inViewport, imageSrc, documentPreview, fetchData]);

  useEffect(() => {
    return () => { abortRef.current = true; };
  }, []);

  return (
    <div
      ref={rootRef}
      title={error ? documentUpdateActions.recogniseError : undefined}
      className={classNames(
        Css.documentPreview,
        documentPreview && Css.clickable,
        className
      )}>
      <div className={Css.inner} onClick={handlePreviewClick}>
        {imageSrc && (
          <div
            title={imageLoaded ? uiTexts.preview : undefined}
            className={Css.preview}
            style={{ backgroundImage: `url(${imageSrc})` }} />
        )}
        {(!documentPreview || !imageLoaded) && !error && (
          <Preloader className={Css.loader} absolute />
        )}
        {!documentPreview && error && (
          <Icons.FileX className={Css.error} />
        )}
      </div>
      {!!children && (
        <div className={Css.actions}>
          {children}
        </div>
      )}
    </div>
  );
};

export default React.memo(DocumentPreview);
