import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { Badge } from "shards-react";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import ConfidenceAppend from "nlib/common/ConfidenceAppend";
import React from "react";
import SelectItemInput from "nlib/common/SelectItemInput";
import classNames from "classnames";

const IconComponent = () => {
  const { uiTexts } = useSelector(getTextsData);

  return (
    <Badge
      theme="primary"
      className={Css.setByClient}
      data-tooltip={uiTexts.categorizedByClient}>
      <Icons.User />
    </Badge>
  );
};

const Item = (props) => {
  const {
    className,
    transaction,
    item,
    audit,
    disabled,
    placeholder,
    advancedType,
    validateInput,
    onChange,
    ...restProps
  } = props;

  const { uiTexts } = useSelector(getTextsData);

  return (
    <div className={classNames(Css.item, className)}>
      <ConfidenceAppend
        icon={item?.setByClient ? IconComponent : null}
        confidence={audit ? null : item?.confidence}>
        <SelectItemInput
          value={item}
          disabled={disabled}
          valid={!!item?.id}
          placeholder={!item?.name && disabled ? uiTexts.noItem : placeholder}
          invalid={validateInput && !item?.id}
          onChange={onChange}
          {...restProps} />
      </ConfidenceAppend>
    </div>
  );
};

export default React.memo(Item);
