import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import ConfidenceAppend from "nlib/common/ConfidenceAppend";
import React, { useState } from "react";
import SelectLocationInput from "nlib/common/SelectLocationInput";
import VendorValidation from "nlib/common/VendorValidation";

const SelectLocation = (props) => {
  const {
    value,
    audit,
    vendorId,
    disabled,
    placeholder,
    transactionId,
    showVendorValidation,
    onChange,
    ...restProps
  } = props;

  const { uiTexts } = useSelector(getTextsData);

  const [inputOpened, setInputOpened] = useState(false);

  const [validationOpened, setValidationOpened] = useState(false);

  return (
    <VendorValidation
      skip={!showVendorValidation || disabled}
      opened={validationOpened && !inputOpened}
      vendorId={vendorId}
      listName="locations"
      name="location"
      entityValue={value}
      confidence={audit ? null : value?.confidence}
      setOpened={setValidationOpened}
      updateTransaction={onChange}>
      {({ iconComponent, showWarning, showCriticalWarning }) => {
        return (
          <ConfidenceAppend
            icon={iconComponent}
            confidence={audit ? null : value?.confidence}>
            <SelectLocationInput
              disabled={disabled}
              value={value}
              invalid={!!(value?.name && !value?.id)}
              valid={!!(value?.name && value?.id)}
              warning={showWarning}
              border={(showWarning || showCriticalWarning) && "warning"}
              placeholder={!value?.name && disabled ? uiTexts.noLocation : placeholder}
              opened={inputOpened}
              setOpened={setInputOpened}
              onChange={onChange}
              {...restProps} />
          </ConfidenceAppend>
        );
      }}
    </VendorValidation>

  );
};

export default React.memo(SelectLocation);
