import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { Badge } from "nlib/ui";
import {
  checkSelectedBusinessRpaMode,
  getCurrentQuickBooksRealmId,
  getCurrentXeroOrganizationId,
  getSelectedBusinessClasses,
  getSelectedBusinessData,
  getSelectedBusinessId,
  getSelectedBusinessLocations,
  getSelectedBusinessProjects,
  getSelectedBusinessTaxRates
} from "selectors/businesses";
import { getActiveOrganization, getAllUsersData } from "selectors/organizations";
import { getTextsData } from "selectors/texts";
import { useDispatch, useSelector } from "react-redux";
import Actions from "./lib/Actions";
import AdditionalInfo from "./lib/AdditionalInfo";
import Comments from "./lib/Comments";
import Constants from "const/Constants";
import Countries from "const/Countries";
import DataConstants from "const/DataConstants";
import Document from "./lib/Document";
import Item from "./lib/Item";
import OrganizationsActions from "actions/OrganizationsActions";
import React, { useCallback, useMemo } from "react";
import ReasonInput from "nlib/common/ReasonInput";
import SelectCategory from "./lib/SelectCategory";
import SelectClass from "./lib/SelectClass";
import SelectContact from "./lib/SelectContact";
import SelectLocation from "./lib/SelectLocation";
import SelectProject from "./lib/SelectProject";
import SelectTaxRate from "./lib/SelectTaxRate";
import Transactions from "utils/Transactions";
import TransactionsActions from "actions/TransactionsActions";
import Utils from "utils/Utils";
import classNames from "classnames";
import moment from "moment";

const { AUDIT_SUBSECTIONS_ROUTES, LS_KEYS } = Constants;

const {
  STATUSES: { NEED_REACTION, EXPORTED, TO_RECONCILE, TO_REPORT, TO_REVIEW },
  ADVANCED_TRANSACTION_TYPES: { TRANSFER, BILL_PAYMENT, RECEIVED_PAYMENT }
} = DataConstants;

const ALWAYS_VISIBLE_INPUTS_COUNT = 1;

const ExtraCell = (props) => {
  const {
    disabled,
    inProgress,
    auditRoute,
    simplifyLayout = false,
    selected,
    selectable,
    transaction,
    transactionState,
    readyToProcess,
    readyToApprove,
    localReasons,
    refetchTableData,
    onChange,
    onReasonBlur,
    onSelectedChange
  } = props;

  const dispatch = useDispatch();

  const { uiTexts, messages } = useSelector(getTextsData);

  const { countryCode } = useSelector(getActiveOrganization);

  const taxRates = useSelector(getSelectedBusinessTaxRates);

  const classes = useSelector(getSelectedBusinessClasses);

  const locations = useSelector(getSelectedBusinessLocations);

  const projects = useSelector(getSelectedBusinessProjects);

  const rpaMode = useSelector(checkSelectedBusinessRpaMode);

  const quickBooksBusiness = !!useSelector(getCurrentQuickBooksRealmId);

  const usersData = useSelector(getAllUsersData);

  const selectedBusinessId = useSelector(getSelectedBusinessId);

  const xeroBusiness = !!useSelector(getCurrentXeroOrganizationId);

  const {
    settings: { advancedDocumentsWorkflow } = {}
  } = useSelector(getSelectedBusinessData);

  const {
    id: transactionId,
    description,
    status,
    type,
    reason: initialReason,
    category: initialCategory,
    usesItems,
    extraData,
    comments,
    documentId,
    reconciled = false,
    askClientInfo,
    aiProcessing
  } = transaction;

  const {
    vendorId,
    reason,
    manualMode = false,
    address = {},
    category = {},
    item = {},
    taxRate = {},
    class: classValue = {},
    location = {},
    project = {}
  } = transactionState;

  const advancedType = useMemo(() => Transactions.getTransactionAdvancedType({ type, extraData }), [type, extraData]);

  const statusExported = status === EXPORTED;

  const billPayment = advancedType === BILL_PAYMENT;

  const receivePayment = advancedType === RECEIVED_PAYMENT;

  const typeTransfer = advancedType === TRANSFER;

  const disableInputs = disabled || inProgress || aiProcessing || (statusExported && !quickBooksBusiness) || status === TO_REPORT
    || (!!auditRoute && xeroBusiness);

  const disabledContactInput = typeTransfer || disableInputs;

  const usCountry = countryCode === Countries.US;

  const needReactionStatus = status === NEED_REACTION;

  const seeTheDocument = !auditRoute && !!documentId && advancedDocumentsWorkflow;

  const disabledExtraInput = seeTheDocument || typeTransfer || (
    auditRoute ? (xeroBusiness || billPayment || receivePayment) : disableInputs
  );

  const disabledCategoryInput = billPayment || receivePayment || disableInputs || seeTheDocument;

  const showVendorValidation = (!auditRoute || auditRoute === AUDIT_SUBSECTIONS_ROUTES.UNUSUAL_CATEGORY)
  && (status === TO_RECONCILE
    || status === TO_REVIEW
    || status === NEED_REACTION
    || (quickBooksBusiness && (status === EXPORTED)));

  const handleContactChange = useCallback((value) => {
    onChange(transactionId, { address: value, vendorId: value.id });
  }, [transactionId, onChange]);

  const handleReasonChange = useCallback((value) => {
    onChange(transactionId, { reason: value });
  }, [transactionId, onChange]);

  const handleItemChange = useCallback((value) => {
    onChange(transactionId, {
      item: value,
      tags: value.id ? [value.name] : [],
      class: Utils.arrayFindById(classes, value.classId, classValue)
    });
  }, [onChange, transactionId, classes, classValue]);

  const updateTransaction = useCallback((update) => {
    onChange(transactionId, update);
  }, [transactionId, onChange]);

  const handleCategoryChange = useCallback((value) => {
    updateTransaction({ category: value, tags: value.code ? [value.name] : [] });
  }, [updateTransaction]);

  const handleTaxRateChange = useCallback((value) => {
    updateTransaction({ taxRate: value });
  }, [updateTransaction]);

  const handleClassChange = useCallback((newValue) => {
    updateTransaction({ class: newValue });
  }, [updateTransaction]);

  const handleLocationChange = useCallback((newValue) => {
    updateTransaction({ location: newValue });
  }, [updateTransaction]);

  const handleProjectChange = useCallback((newValue) => {
    updateTransaction({ project: newValue });
  }, [updateTransaction]);

  const handleAskClient = useCallback(async() => {
    if (askClientInfo?.askedEmail) {
      const businessUser = usersData.find(({ email }) => email === askClientInfo?.askedEmail);

      const savedEmailLsKey = `${LS_KEYS.LAST_ASKED_BUSINESS_EMAIL}_${selectedBusinessId}`;

      const successful = await dispatch(
        OrganizationsActions.askBusinessUser([transactionId], askClientInfo?.askedEmail, businessUser?.phone)
      );

      if (successful) {
        Utils.storageValue(savedEmailLsKey, `${askClientInfo?.askedEmail}:${moment().unix()}`);
        refetchTableData();
      }
    } else if (!selected) {
      onSelectedChange(transactionId, true);
    }
  }, [
    askClientInfo?.askedEmail,
    selected,
    usersData,
    selectedBusinessId,
    dispatch,
    transactionId,
    onSelectedChange,
    refetchTableData
  ]);

  const handleDocumentAttach = useCallback(async(skip, newDocumentId) => {
    if (auditRoute === AUDIT_SUBSECTIONS_ROUTES.ALL_CATEGORIZED) {
      await dispatch(TransactionsActions.editTransaction(transactionId, { documentId: newDocumentId }, true, true));
    }
    refetchTableData([], true);
  }, [auditRoute, dispatch, refetchTableData, transactionId]);

  const handleManualModeSet = useCallback(() => {
    updateTransaction({ manualMode: true });
  }, [updateTransaction]);

  const showDocument = documentId || (auditRoute
    ? (!advancedDocumentsWorkflow && quickBooksBusiness)
    : (!statusExported || quickBooksBusiness));

  const additionalInputsCount = useMemo(() => {
    if (simplifyLayout) return ALWAYS_VISIBLE_INPUTS_COUNT;

    return [
      !usCountry && !!taxRates.length, !!classes.length, !!locations.length, !!projects.length
    ].filter(Boolean).length + ALWAYS_VISIBLE_INPUTS_COUNT;
  }, [classes.length, locations.length, projects.length, simplifyLayout, taxRates.length, usCountry]);

  return (
    <div className={Css.extraCell}>
      <div className={Css.upper}>
        <div className={Css.description}>
          <div className={Css.content}>
            <div className={classNames(Css.text, !description && Css.muted)} title={description}>
              {description ? <Icons.Info /> : <Icons.Warning className={Css.warning} />}
              <span>
                {description ? description : `${uiTexts.noBankDescriptionOrMemo} :(`}
              </span>
            </div>
          </div>
        </div>
        {!auditRoute && rpaMode && statusExported && !inProgress && (
          <Badge
            className={classNames(Css.badge, !reconciled && Css.active)}
            value={reconciled
              ? (quickBooksBusiness ? uiTexts.matched : uiTexts.reconciled)
              : (quickBooksBusiness ? uiTexts.awaitingMatching : uiTexts.awaitingReconciliation)} />
        )}
      </div>
      <div className={Css.fields}>
        <div className={Css.contact}>
          <SelectContact
            disabled={disabledContactInput}
            auditRoute={auditRoute}
            className={Css.input}
            vendorId={vendorId}
            address={address}
            transaction={transaction}
            transactionState={transactionState}
            onChange={handleContactChange}
            updateTransaction={updateTransaction} />
        </div>
        <div className={Css.dynamicBlock} data-width={`f${additionalInputsCount}`}>
          {rpaMode && needReactionStatus && !manualMode
            ? (
              <div className={Css.reason}>
                <ReasonInput
                  disabled={disableInputs}
                  value={reason}
                  audit={auditRoute}
                  localReasons={localReasons}
                  placeholder={messages.describeTransactionForAi}
                  onChange={handleReasonChange}
                  onBlur={onReasonBlur} />
              </div>
            ) : (
              <>
                {usesItems
                  ? (
                    <Item
                      audit={!!auditRoute}
                      className={Css.input}
                      disabled={disabledCategoryInput}
                      placeholder={seeTheDocument ? uiTexts.seeTheDocument : undefined}
                      transaction={transaction}
                      item={!seeTheDocument && item}
                      advancedType={advancedType}
                      validateInput={!disabledContactInput && (!needReactionStatus || manualMode || item.name)}
                      onChange={handleItemChange} />
                  )
                  : (
                    <SelectCategory
                      type={type}
                      audit={!!auditRoute}
                      className={Css.input}
                      disabled={disabledCategoryInput}
                      placeholder={seeTheDocument ? uiTexts.seeTheDocument : undefined}
                      showVendorValidation={showVendorValidation || auditRoute === AUDIT_SUBSECTIONS_ROUTES.UNUSUAL_CATEGORY}
                      transaction={transaction}
                      category={!seeTheDocument && category}
                      vendorId={vendorId}
                      advancedType={advancedType}
                      validateCategory={!disabledContactInput && (!needReactionStatus || manualMode || category.name)}
                      onChange={handleCategoryChange} />
                  )}
                {!simplifyLayout && !usCountry && !!taxRates.length && (
                  <div className={Css.input}>
                    <SelectTaxRate
                      audit={!!auditRoute}
                      disabled={disabledExtraInput}
                      showVendorValidation={showVendorValidation}
                      value={!seeTheDocument && taxRate}
                      vendorId={vendorId}
                      placeholder={seeTheDocument ? uiTexts.seeTheDocument : undefined}
                      onChange={handleTaxRateChange} />
                  </div>
                )}
                {!simplifyLayout && !!classes.length && (
                  <div className={Css.input}>
                    <SelectClass
                      audit={!!auditRoute}
                      disabled={disabledExtraInput}
                      showVendorValidation={showVendorValidation}
                      value={!seeTheDocument && classValue}
                      vendorId={vendorId}
                      placeholder={seeTheDocument ? uiTexts.seeTheDocument : undefined}
                      onChange={handleClassChange} />
                  </div>
                )}
                {!simplifyLayout && !!locations.length && (
                  <div className={Css.input}>
                    <SelectLocation
                      audit={!!auditRoute}
                      disabled={disabledExtraInput}
                      showVendorValidation={showVendorValidation}
                      value={!seeTheDocument && location}
                      vendorId={vendorId}
                      placeholder={seeTheDocument ? uiTexts.seeTheDocument : undefined}
                      onChange={handleLocationChange} />
                  </div>
                )}
                {!simplifyLayout && !!projects.length && (
                  <div className={Css.input}>
                    <SelectProject
                      audit={!!auditRoute}
                      disabled={disabledExtraInput}
                      showVendorValidation={showVendorValidation}
                      value={!seeTheDocument && project}
                      vendorId={vendorId}
                      placeholder={seeTheDocument ? uiTexts.seeTheDocument : undefined}
                      onChange={handleProjectChange} />
                  </div>
                )}
              </>
            )}
        </div>
      </div>
      <div className={Css.footer}>
        {!simplifyLayout && !inProgress && (
          <>
            {showDocument && (
              <Document
                disabled={disabled || aiProcessing}
                vendorId={vendorId}
                advancedType={advancedType}
                transaction={transaction}
                onDocumentAttach={handleDocumentAttach} />
            )}
            <Comments
              disabled={disabled}
              audit={!!auditRoute}
              status={status}
              transactionId={transactionId}
              comments={comments}
              onAskClient={handleAskClient} />
            <AdditionalInfo
              disabled={disabled || aiProcessing}
              disabledCategorySelect={disabledCategoryInput || seeTheDocument}
              auditRoute={auditRoute}
              usesItems={usesItems}
              status={status}
              type={type}
              vendorId={vendorId}
              category={category}
              initialReason={initialReason}
              initialCategory={initialCategory}
              onCategoryChange={handleCategoryChange} />
          </>
        )}
        {!inProgress && (
          <Actions
            auditRoute={auditRoute}
            selected={selected}
            selectable={selectable}
            readyToProcess={readyToProcess}
            readyToApprove={readyToApprove}
            transaction={transaction}
            transactionState={transactionState}
            onManualModeSet={handleManualModeSet}
            refetchTableData={refetchTableData}
            onSelectedChange={onSelectedChange} />
        )}
      </div>
    </div>
  );
};

export default React.memo(ExtraCell);
