import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { checkIsBusinessUser, getUserRestrictions } from "selectors/user";
import {
  checkSelectedBusinessHasWhoAsk,
  checkSelectedBusinessRpaMode,
  getCurrentXeroOrganizationId,
  getSelectedBusinessClasses,
  getSelectedBusinessData,
  getSelectedBusinessLocations,
  getSelectedBusinessProjects,
  getSelectedBusinessTaxRates
} from "selectors/businesses";
import { getActiveOrganization } from "selectors/organizations";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import Button from "nlib/ui/Button";
import Card from "nlib/common/Card";
import CategoryAndReasonInput from "nlib/common/CategoryAndReasonInput";
import ChangeStatusSelector from "nlib/common/ChangeStatusSelector";
import Countries from "const/Countries";
import DataConstants from "const/DataConstants";
import ExtraDataInput from "nlib/pages/TransactionsPage/lib/ExtraDataInput";
import React, { useCallback, useMemo, useState } from "react";
import SelectContactInput from "nlib/common/SelectContactInput";
import TransactionsStatuses from "nlib/pages/TransactionsPage/TransactionsStatuses";
import Utils from "utils/Utils";
import classNames from "classnames";

const { STATUSES: { NEED_REACTION, TO_REVIEW } } = DataConstants;

const getInitialState = () => ({
  address: { name: "" },
  category: {},
  reason: "",
  class: {},
  location: {},
  project: {},
  taxRate: {}
});

const BulkActions = (props) => {
  const {
    audit,
    disabled,
    showMarkAsCorrect,
    simplifyLayout = false,
    className,
    selectedTransactions,
    selectedTransactionsData = [],
    localReasons = [],
    usesItems,
    onReasonBlur,
    onCancel,
    onEdit
  } = props;

  const { uiTexts, messages } = useSelector(getTextsData);

  const classes = useSelector(getSelectedBusinessClasses);

  const locations = useSelector(getSelectedBusinessLocations);

  const projects = useSelector(getSelectedBusinessProjects);

  const taxRates = useSelector(getSelectedBusinessTaxRates);

  const businessUser = useSelector(checkIsBusinessUser);

  const userRestrictions = useSelector(getUserRestrictions);

  const xeroBusiness = !!useSelector(getCurrentXeroOrganizationId);

  const { businessOrganization, countryCode } = useSelector(getActiveOrganization);

  const rpaMode = useSelector(checkSelectedBusinessRpaMode);

  const selectedBusinessHasWhoAsk = useSelector(checkSelectedBusinessHasWhoAsk);

  const usCountry = countryCode === Countries.US;

  const {
    settings: {
      allowClientPayeeSelection,
      allowClientClassSelection,
      allowClientLocationSelection,
      allowClientProjectSelection,
      allowClientTaxRateSelection
    } = {}
  } = useSelector(getSelectedBusinessData);

  const [data, setData] = useState(getInitialState);

  const hasDataToSave = [
    data.vendorId,
    data.reason.trim(),
    data.category.code,
    data.class.id,
    data.location.id,
    data.project.id,
    data.taxRate.id
  ].some(Boolean);

  const showExtraData = ((!businessUser || allowClientClassSelection) && !!classes.length)
    || ((!businessUser || allowClientLocationSelection) && !!locations.length)
    || ((!businessUser || allowClientProjectSelection) && !!projects.length)
    || ((!businessUser || allowClientTaxRateSelection) && !usCountry && !!taxRates.length);

  const disableContactInput = businessUser && selectedTransactionsData.every(({ status }) => {
    return status !== NEED_REACTION;
  });

  const allSelectedTransactionsNeedReaction = useMemo(() => {
    return selectedTransactionsData.every(({ status }) => status === NEED_REACTION);
  }, [selectedTransactionsData]);

  const transaction = useMemo(() => ({ usesItems, status: NEED_REACTION }), [usesItems]);

  const statusesList = useMemo(() => {
    return Object.values(
      TransactionsStatuses.getStatusData([
        undefined,
        businessOrganization,
        rpaMode && !selectedBusinessHasWhoAsk,
        businessUser
      ])
    );
  }, [businessOrganization, selectedBusinessHasWhoAsk, businessUser, rpaMode]);

  const bulkUpdateData = useMemo(() => {
    const reason = data.reason.trim();

    return {
      ...(data.vendorId ? {
        address: data.address,
        vendorId: data.vendorId
      } : null),
      ...(data.category.code ? {
        category: data.category,
        tags: data.tags,
        directCategorySelection: true
      } : null),
      ...(data.class.id ? { class: data.class } : null),
      ...(data.location.id ? { location: data.location } : null),
      ...(data.project.id ? { project: data.project } : null),
      ...(data.taxRate.id ? { taxRate: data.taxRate } : null),
      ...((reason && !data.category.code) ? { reason } : null)
    };
  }, [
    data.address,
    data.category,
    data.class,
    data.location,
    data.project,
    data.reason,
    data.tags,
    data.taxRate,
    data.vendorId
  ]);

  const updateData = useCallback((newData) => {
    setData((prev) => ({ ...prev, ...newData }));
  }, []);

  const handleChange = useCallback((id, newData) => updateData(newData), [updateData]);

  const handleContactChange = useCallback((value) => {
    updateData({ address: value, vendorId: value.id });
  }, [updateData]);

  const handleChangeStatusSelectorChange = useCallback((value) => {
    onEdit({ status: value });
  }, [onEdit]);

  const handleMarkAsCorrectClick = useCallback(() => {
    onEdit({ markAsCorrect: true, ...bulkUpdateData });
  }, [bulkUpdateData, onEdit]);

  const handleEditClick = useCallback(() => {
    onEdit(bulkUpdateData);
  }, [bulkUpdateData, onEdit]);

  return (
    <Card className={classNames(Css.bulkActions, className)} disabled={disabled}>
      <div className={Css.text}>
        {Utils.replaceTextVars(messages.editCountTransaction, { count: selectedTransactions.length })}
      </div>
      {(!audit || !xeroBusiness) && (
        <div className={Css.inputs}>
          {(!businessUser || allowClientPayeeSelection) && (
            <div className={Css.input}>
              <SelectContactInput
                useWidget
                valid={!!(data.address.name && data.vendorId)}
                invalid={!!(data.address.name && !data.vendorId)}
                value={data.address}
                onlyCustomers={!!usesItems}
                onChange={handleContactChange} />
            </div>
          )}
          <CategoryAndReasonInput
            audit={audit}
            className={Css.input}
            validate={!!data.category.name}
            disabled={disableContactInput}
            transaction={transaction}
            transactionState={data}
            localReasons={localReasons}
            onChange={handleChange}
            onReasonBlur={onReasonBlur} />
          {!simplifyLayout && showExtraData && (
            <div className={Css.input}>
              <ExtraDataInput
                disabled={disableContactInput}
                transactionState={data}
                onChange={handleChange}
                onReasonBlur={onReasonBlur} />
            </div>
          )}
          {!audit && !businessUser && allSelectedTransactionsNeedReaction && (
            <div className={Css.selectStatus}>
              <ChangeStatusSelector
                statusesList={statusesList}
                excludedStatuses={[
                  userRestrictions.transactionsUpdate && TO_REVIEW,
                  NEED_REACTION
                ]}
                onChange={handleChangeStatusSelectorChange} />
            </div>
          )}
        </div>
      )}
      <div className={Css.buttons}>
        <Button
          large
          primary={!simplifyLayout}
          danger={simplifyLayout}
          light={!simplifyLayout}
          outline={simplifyLayout}
          icon={!simplifyLayout && Icons.X}
          onClick={onCancel}>
          {simplifyLayout
            ? <Icons.X />
            : uiTexts.cancel}
        </Button>
        {audit && showMarkAsCorrect && (
          <Button
            large success outline
            icon={simplifyLayout ? null : Icons.ThumbsUp}
            onClick={handleMarkAsCorrectClick}>
            {simplifyLayout ? <Icons.ThumbsUp /> : uiTexts.markAsCorrect}
          </Button>
        )}
        {(!audit || !xeroBusiness) && (
          <Button
            large primary
            icon={simplifyLayout ? null : Icons.Check}
            disabled={!hasDataToSave || disableContactInput}
            onClick={handleEditClick}>
            {simplifyLayout ? <Icons.Check /> : uiTexts.confirmEditing}
          </Button>
        )}
      </div>
    </Card>
  );
};

export default React.memo(BulkActions);
